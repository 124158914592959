import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import {
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey, Keypair, clusterApiUrl, SystemProgram, Transaction } from '@solana/web3.js';
import React, { FC, ReactNode, useMemo, useCallback } from 'react';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';

const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
    'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL'
);

async function findAssociatedTokenAddress(walletAddress: PublicKey, tokenMintAddress: PublicKey): Promise<PublicKey> {
    return (
        await PublicKey.findProgramAddress(
            [walletAddress.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), tokenMintAddress.toBuffer()],
            SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
        )
    )[0];
}

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

export const App: FC = () => {
    return (
        <Context>
            <Content />
            <DonateOneLamportToUnDDAddress />
        </Context>
    );
};

const Context: FC<{ children: ReactNode }> = ({ children }) => {

    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    //todo: allow the user to pick
    const network = WalletAdapterNetwork.Mainnet; //.DevNet;

    //https://github.com/solana-labs/wallet-adapter/blob/16fb5cc/packages/core/base/src/types.ts#L7
    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    //https://api.mainnet-beta.solana.com
    //soundwave, superior:
    //https://rpc.ankr.com/solana

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolflareWalletAdapter(),
            new TorusWalletAdapter(),
            new LedgerWalletAdapter(),
            new SolletWalletAdapter({ network }),
            new SolletExtensionWalletAdapter({ network }),
        ],
        [network]
    );

    //console.log("loading available wallets");
    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
	
    );
};

const Content: FC = () => {
    return <WalletMultiButton />;
};

export const DonateOneLamportToUnDDAddress: FC = () => {
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();

    const onClick = useCallback(async () => {
        if (!publicKey) throw new WalletNotConnectedError();
        const endpoint = "https://rpc.ankr.com/solana";

        const balance = await connection.getBalance(publicKey);
        
	    //This returns the entire sol balance; we'll use this number for some games someday
        console.log(balance);
        const accountInfo = await connection.getAccountInfo(publicKey);
        console.log(accountInfo);

        //Easily findable on solscan!
        const mintId = '2bhrcaTiAHwhMStrnKUexZ3H4G96pRvT8K3WkbP5YsJx';
        const unDD = 'D7G1c6A2msKSsUyTyDcu5FpLvPKDsrDChJCVZ1iVQWSE';
        const accountTokenInfo = await connection.getTokenAccountsByOwner(publicKey, { mint: new PublicKey(unDD) });

        console.log('Your Token Account Information:');
        console.log(accountTokenInfo);

	if (!accountTokenInfo.value[0]) {
		console.log("This wallet is sadly short of UnDD tokens.");
	}
	else{
		console.log("These UnDD tokens will one day have purpose!");

        	console.log(accountTokenInfo.value[0]);



	    //we need this value to get the token balance
        	const key = accountTokenInfo.value[0].pubkey.toBase58();

        //assumes this is the only token in the list, as we pass the mint authority up above; when would this have >1 value?
        	const balance1 = await connection.getTokenAccountBalance(accountTokenInfo.value[0].pubkey);
        	console.log(balance1); //now prints the balance of the UnDD tokens

        //https://docs.solana.com/developing/clients/jsonrpc-api

        //debug: 
		console.log(key);
	}

	//this is 0.0125 sol
	const LAMPORTS_PER_SOL = 1000000000;
	const donationAmount = 0.0125 * LAMPORTS_PER_SOL;

	console.log("Preparing to donate in sol lamperts (1000000000 per sol): "+donationAmount.toString());

        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: new PublicKey("D43ZoV8WCDGBaTtTqSLwkrgQUxQyx8We4p7xJHrRMwM1"),
                lamports: donationAmount,
            })
        );

	//future:https://docs.solana.com/developing/programming-model/calling-between-programs

        const signature = await sendTransaction(transaction, connection);

        await connection.confirmTransaction(signature, 'processed');
    }, [publicKey, sendTransaction, connection]);

    return (
        <button onClick={onClick} disabled={!publicKey} 
style={ publicKey ? {} : { display: 'none' }} 
 className="btn btn-outline-light btnradius me-2" title="Tap or click me to send a donation, with your approval of course!">
            Buy Poseidon a Coffee ☕
        </button>
    );
};
